import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WindowIcon from '@mui/icons-material/Window';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
      button: {
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: '#141D24',
          color: 'white',
        },
      },
      button2: {
        backgroundColor: '#141D24',
        '&:hover': {
          backgroundColor: '#141D24',
          color: 'white',
        },
      },
      button3: {
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: '#4f5a6b',
          color: 'white',
        },
      },
}))


export default function MediaCard() {
  const classes = useStyles();

  return (
    <Card align="center" sx={{ maxWidth: 350, backgroundColor: '#4f5a6b', color: 'white' }}>
      <CardMedia

      />
      <CardContent>
        <Typography variant="h3" component="div">
          <center>
          <WindowIcon fontSize="lg" sx={{paddingTop: 1.75}} /> 
          </center>
          <center>
          <span>Windows ISO</span>
          </center>
          <Button
                sx={{
                    color: '#141D24',
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: '#141D24',
                      color: 'white',
                    },   
          }}>10</Button>
          <span> </span>
          <Button sx={{
                backgroundColor: '#141D24',
                color: 'white',
                '&:hover': {
                  backgroundColor: 'white',
                  color: '#141D24',
                },
          }}>11</Button>
          
        </Typography>
      </CardContent>
    </Card>
  );
}

import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
      width: 250,
      color: '#ffffff',
      //backgroundColor: '#141D24', 
      backgroundColor: '#4f5a6b',
    },
  },
};

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#4f5a6b",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
      "& .MuiOutlinedInput-input": {
        color: "white"
      },
      "&:hover .MuiOutlinedInput-input": {
        color: "white"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "white",
      },
      "& .MuiInputLabel-outlined": {
        color: "#4f5a6b"
      },
      "&:hover .MuiInputLabel-outlined": {
        color: "white",
      },
      "& .MuiInputLabel-outlined.Mui-focused": {
        color: "white"
      },
      "& .MuiSelect-icon": {
        color: "#4f5a6b"
      },
    },
    button: {
        color: '#141D24',
        backgroundColor: "white",
        '&:hover': {
          backgroundColor: '#4f5a6b',
          color: 'white',
        },
       '&:disabled': {
          backgroundColor: '#1b2730',
          color: '#141D24',
       }, 
    },
  });

export default function GroupedSelect() {
  const classes = useStyles();
  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 240 }} className={classes.root}>
        <InputLabel htmlFor="grouped-select">Edition</InputLabel>
        <Select 
          defaultValue="" 
          id="grouped-select" 
          label="Grouping" 
          className={classes.root}
          MenuProps={MenuProps}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <ListSubheader>Windows 10</ListSubheader>
          <MenuItem value={0}>W10 Multi</MenuItem>
          <MenuItem value={1}>W10 Pro</MenuItem>
          <MenuItem value={2}>W10 Home</MenuItem>
          <ListSubheader>Windows 11</ListSubheader>
          <MenuItem value={3}>W11 Multi</MenuItem>
          <MenuItem value={4}>W11 Pro</MenuItem>
          <MenuItem value={5}>W11 Home</MenuItem>
          <ListSubheader>Windows 7</ListSubheader>
          <MenuItem value={6}>W7 Multi</MenuItem>
          <MenuItem value={7}>W7 Pro</MenuItem>
          <MenuItem value={8}>W7 Home Premium</MenuItem>
          <MenuItem value={9}>W7 Home Basic</MenuItem>
          <MenuItem value={10}>W7 Starter</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 120 }} className={classes.root}>
        <InputLabel htmlFor="grouped-select">Bitness</InputLabel>
        <Select defaultValue="" 
          id="grouped-select" 
          label="Grouping" 
          className={classes.root}
          MenuProps={MenuProps}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={1}>64bit</MenuItem>
          <MenuItem value={2}>32bit</MenuItem>
          <MenuItem value={3}>Both</MenuItem>
        </Select>
      </FormControl>
      <div style={{ paddingTop: 15 }}>
      <Button style={{ m: 1, minWidth: 120, minHeight: 50 }} className={classes.button}>Download</Button>
      </div>
    </div>
  );
}

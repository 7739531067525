import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
      width: 250,
      color: '#ffffff',
      //backgroundColor: '#141D24', 
      backgroundColor: '#4f5a6b',
    },
  },
};

const tier = [
  {
      key: 1,
      name: 'Pro Plus',
      link: 'https://dlm2.download.intuit.com/akdlm/SBD/QuickBooks/2022/Latest/QuickBooksProSub2022.exe',
  },
  {
      key: 2,
      name: '2021',
      link: 'https://dlm2.download.intuit.com/akdlm/SBD/QuickBooks/2021/Latest/QuickBooksPro2021.exe',
  },
  {
      key: 3,
      name: '2020',
      link: 'https://dlm2.download.intuit.com/akdlm/SBD/QuickBooks/2020/Latest/QuickBooksPro2020.exe',
  },
  {
      key: 4,
      name: '2019',
      link: 'https://dlm2.download.intuit.com/akdlm/SBD/QuickBooks/2019/Latest/QuickBooksPro2019.exe',
  },
  {
      key: 5,
      name: '2018',
      link: 'https://dlm2.download.intuit.com/akdlm/SBD/QuickBooks/2018/Latest/QuickBooksPro2018.exe',
  },
  {
      key: 6,
      name: '2017',
      link: 'https://dlm2.download.intuit.com/akdlm/SBD/QuickBooks/2017/Latest/QuickBooksPro2017.exe',
  },
  {
      key: 7,
      name: '2016',
      link: 'https://dlm2.download.intuit.com/akdlm/SBD/QuickBooks/2016/Latest/QuickBooksPro2016.exe',
  },
  {
      key: 8,
      name: '2015',
      link: 'https://dlm2.download.intuit.com/akdlm/SBD/QuickBooks/2015/Latest/QuickBooksPro2015.exe',
  },
  {
      key: 9,
      name: '2014',
      link: 'https://dlm2.download.intuit.com/akdlm/SBD/QuickBooks/2014/Latest/QuickBooksPro2014.exe',
  },
  {
      key: 10,
      name: '2013',
      link: 'https://dlm2.download.intuit.com/akdlm/SBD/QuickBooks/2013/Latest/QuickBooksPro2013.exe',
  },
  {
      key: 11,
      name: '2012',
      link: 'https://dlm3.download.intuit.com/SBD/QuickBooks/2012/US_R16/QuickBooksPro2012.exe',
  },
  {
      key: 12,
      name: '2011',
      link: 'https://dlm3.download.intuit.com/SBD/QuickBooks/2011/R14/QuickBooksPro2011.exe',
  },
  {
      key: 13,
      name: '2010',
      link: 'https://dlm3.download.intuit.com/SBD/QuickBooks/2010/R17/QuickBooksPro2010.exe',
  },
];

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#4f5a6b",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
      },
      "& .MuiOutlinedInput-input": {
        color: "white"
      },
      "&:hover .MuiOutlinedInput-input": {
        color: "white"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "white",
      },
      "& .MuiInputLabel-outlined": {
        color: "#4f5a6b"
      },
      "&:hover .MuiInputLabel-outlined": {
        color: "white",
      },
      "& .MuiInputLabel-outlined.Mui-focused": {
        color: "white"
      },
      "& .MuiSelect-icon": {
        color: "#4f5a6b"
      },
    },
    button: {
        color: '#141D24',
        backgroundColor: "white",
        '&:hover': {
          backgroundColor: '#4f5a6b',
          color: 'white',
        },
       '&:disabled': {
          backgroundColor: '#1b2730',
          color: '#141D24',
       }, 
    },
  });

export default function MultipleSelectCheckmarks({parentToChild}) {
  const classes = useStyles();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    //console.log(parentToChild);
  };

  function sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

let isDownloading = false

function DownloadInstallers() {
    //window.open(tier.buttonLink, "_self")
    //console.log(personName)
    

    //var i = 0;
    //while (i<personName.length) {
    //    if (personName[i].includes(tier)) window.open(.link, "_self");
    //    i++;
    //}

    //tier.forEach(choice => 
    //    console.log(choice.name)
    //    if (choice.name.includes(personName)) window.open(choice.link, "_self");
    //);

    //var i, l = tier.length;
    //for (i = 0; i < l; i += 1) {
    //    if (tier.includes(personName)) 
    //        console.log(tier[i])
    //    continue;
    //}

    
    for (const item of tier) {
      
        if (personName.includes(item.name)) {
            //window.open(item.link, "_self")
            window.open(item.link, '_blank', 'noopener,noreferrer')
            //console.log("1 dl triggered")
            sleep(parentToChild * 1000);
            //console.log(parentToChild * 1000);
        }
      
    }
    //personName.length = 0;

    //for (const entry of personName) {
    //    entry.push()
    //};
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3.5,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'white',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: '#141D24',
  },
}));

  return (
    <div>
      <center>
      <h2>Desktop Pro</h2>
      <FormControl className={classes.root} sx={{ justifyContent: 'center', m: 1, width: 325}}>
      {/*
      {isDownloading && <BorderLinearProgress />}
      */}
      
        <InputLabel id="demo-multiple-chip-label">Version</InputLabel>
        <Select
          style={{ justifyContent: 'center' }}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Version" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip style={{color: 'white', backgroundColor: '#4f5a6b'}} key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >

          {tier.map((qbArr) => (
            <MenuItem key={qbArr.key} value={qbArr.name}>
              <Checkbox style={{color: 'white'}} checked={personName.indexOf(qbArr.name) > -1} />
              <ListItemText primary={qbArr.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <p></p>
      <Button style={{width:115}} disabled={personName.length === 0} className={classes.button} onClick={() => { let isDownloading = true; DownloadInstallers(); isDownloading = false;}}>
        {'Download'}
      </Button>
      </center>
    </div>
  );
}

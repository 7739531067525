import * as React from 'react';
import Slider from '@mui/material/Slider';
import { withStyles } from '@material-ui/core/styles';

function valuetext(value) {
    return `${value} Seconds`;
}

const CustomSlider = withStyles({
    root: {
        color: "white",
        height: 3,
        padding: "13px 0",
    },
    valueLabelLabel: {
        color: '#4f5a6b',
        backgroundColor: '#4f5a6b',
    },
    mark: {
        color: 'white',
    },
    rail: {
        color: '#4f5a6b',
    },
    track: {
        height: 4,
        borderRadius: 2,
        color: "white",
    },
    thumb: {
        height: 30,
        width: 30,
        color: "#4f5a6b",
        backgroundColor: 'white',
        border: "3px solid white",
        marginTop: 0,
        marginLeft: 0,
    },
    //'& .MuiSlider-valueLabel': {
    //    lineHeight: 1.2,
    //    fontSize: 12,
    //    background: 'unset',
    //    padding: 0,
    //    width: 32,
    //    height: 32,
    //    borderRadius: '50% 50% 50% 0',
    //    backgroundColor: '#52af77',
    //    transformOrigin: 'bottom left',
    //    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    //    '&:before': { display: 'none' },
    //    '&.MuiSlider-valueLabelOpen': {
    //      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    //    },
    //    '& > *': {
    //      transform: 'rotate(45deg)',
    //    },
    //  },
})(Slider);

export default function DiscreteSlider({childToParent}) {

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    childToParent(value);
  };

  return (

    <div>
        
        <CustomSlider
            onChange={handleChange}
            aria-label="Seconds"
            defaultValue={7}
            getAriaValueText={valuetext}
            valueLabelDisplay="auto"
            step={1}
            marks
            min={4}
            max={45}
        />
    </div>
  );
}
